<template>
  <div>

    <vs-popup title="Registrar Ocorrência" :active.sync="popUpOcorrencia.exibir" v-if="popUpOcorrencia.exibir">
      <ExibirErrosConcept :errorsValidacao="errorsValidacao" ref="errosComponent"></ExibirErrosConcept>
      <div class="vx-row mt-2">
        <div class="vx-col w-1/2">
            <label class="vs-input--label">Data/Hora do Contato</label>
            <flat-pickr v-model="popUpOcorrencia.dataHoraContatoComCliente" :config="configDateTimePicker" placeholder="Data"/>
        </div>
        <div class="vx-col w-1/2">   
            <label class="vs-input--label">Em manutenção ?</label>
            <v-select                             
                :options="listaOpcoesSIM_NAO"                   
                :reduce="tipo => tipo.data" 
                v-model="popUpOcorrencia.emManutencao"       
                class="v-select">
            </v-select>                                           
        </div>
      </div>
      <div class="vx-row mt-2">
        <div class="vx-col w-1/2">        
            <label class="vs-input--label">Nome do Contato</label>
            <vs-input class="w-full" v-model="popUpOcorrencia.nomeContato" maxlength="100"/>              
        </div>
        <div class="vx-col w-1/2">
            <label class="vs-input--label">Telefone do Contato</label>
            <vs-input class="w-full" v-model="popUpOcorrencia.telefoneContato" v-mask="['(##) #####-####', '(##) ####-####']"/>        
        </div>
      </div>
      <div class="vx-row mt-2">
        <div class="vx-col w-full">
            <label class="vs-input--label">Descrição</label>
            <vs-textarea v-model="popUpOcorrencia.descricao" counter="1000" maxlength="4096" rows="6"/>
        </div>
      </div>
      <div class="vx-row mt-2">
        <div class="vx-col w-full">
            <label class="vs-input--label">Tratativa</label>
            <vs-textarea v-model="popUpOcorrencia.tratativa" counter="1000" maxlength="4096"  rows="6"/>
        </div>
      </div>
      <div class="vx-row mt-2">
        <div class="vx-col w-full">
            <label class="vs-input--label">Resposta Cliente</label>
            <vs-textarea v-model="popUpOcorrencia.respostaCliente" counter="1000" maxlength="4096" rows="6"/>
        </div>
      </div>
      <div class="vx-row mt-2">
        <div class="vx-col w-1/2">
            <label class="vs-input--label">Retirar do relatório até:</label>
            <flat-pickr v-model="popUpOcorrencia.desconsiderarAte" :config="configDateYmdPicker" placeholder="Data"/>
        </div>
      </div>
      <div class="vx-row mt-5">
        <div class="vx-col w-full">
            <vs-button class="w-full" @click="alterarEmLote" color="success">Gravar</vs-button>            
        </div>
        
      </div>
    </vs-popup>

    <vx-card class="mb-1">
       <vs-row vs-type="flex" vs-justify="space-between" >
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="3">         
              <label class="vs-input--label mr-2">Cliente</label>
              <vs-input class="mb-4 md:mb-0 w-full" v-model="filtro.nomeCliente" @keyup.enter="fetchLista(filtro)" />
          </vs-col>
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="3">         
              <label class="vs-input--label mr-2">Placa</label>
              <vs-input class="mb-4 md:mb-0 w-full" v-model="filtro.placaVeiculo" @keyup.enter="fetchLista(filtro)" />
          </vs-col>          
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="3">         
              <label class="vs-input--label mr-2">Usuário</label>
              <vs-input class="mb-4 md:mb-0 w-full" v-model="filtro.nomeUsuario" @keyup.enter="fetchLista(filtro)" />
          </vs-col>          
          <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="3">
            <vs-button class="mr-2" color="primary" type="filled" icon-pack="feather" icon="icon-search" @click="fetchLista(filtro)"></vs-button>
            <vs-button class="mr-2" color="dark" type="border" icon-pack="feather" icon="icon-file" @click="limparFiltros"></vs-button>  
            <vs-button class="mr-2" color="warning" type="filled" icon-pack="feather" icon="icon-plus" @click="routeNovoRegistro"></vs-button>                    
          </vs-col>
      </vs-row>
      <vs-row vs-type="flex" vs-justify="space-between" class="mt-2" >
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="3">         
          <label class="vs-input--label mr-2 w-full">Cadastro</label>
          <flat-pickr v-model="filtro.dataHoraOcorrenciaInicio" :config="configDatePicker" placeholder="Data Início"/>          
          <flat-pickr v-model="filtro.dataHoraOcorrenciaFim" :config="configDatePicker" placeholder="Data Fim"/>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="3">         
          <label class="vs-input--label mr-2 w-full">Tratativa</label>
          <flat-pickr v-model="filtro.dataHoraTratativaInicio" :config="configDatePicker" placeholder="Data Início"/>          
          <flat-pickr v-model="filtro.dataHoraTratativaFim" :config="configDatePicker" placeholder="Data Fim"/>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="6">         
          <label class="vs-input--label text-success ml-auto">Ocorrências selecionadas:</label> <h6 class="ml-4 mr-4">{{qtdRegistrosSelecionados }} </h6>
          <vx-tooltip text="Registrar ocorrência em lote.">  
            <vs-button v-if="qtdRegistrosSelecionados" color="rgb(11, 189, 135)" type="filled" icon-pack="feather" icon="icon-alert-circle" @click="exibirPopUpRegistroOcorrencia"></vs-button>          
          </vx-tooltip>  
        </vs-col>
      </vs-row>
    </vx-card>
     
    <h6 class="mt-2 text-danger"> {{dadosDaGrid.length }} registros </h6>

    <ag-grid-vue
      ref="agGridTable"           
      :gridOptions="configuracaoGrid.gridOptions"
      class="ag-grid-table ag-grid-table-com-selecao ag-grid-altura mt-1"
      :columnDefs="configuracaoGrid.columnDefs"
      :defaultColDef="configuracaoGrid.defaultColDef"
      :rowData="dadosDaGrid"
      :rowHeight="20"
      :headerHeight="25"                    
      rowSelection="multiple"        
      @row-double-clicked="onRowSelectedRegistro"    
      :animateRows="true"
      :floatingFilter="false"
      :pagination="false"
      :paginationAutoPageSize="false"
      :suppressPaginationPanel="true"
      @grid-ready="onGridReady"
      @selection-changed="onSelectionChanged"
      >
    </ag-grid-vue>      
    
  </div>

</template>

<script>
import generico from '@/generico.js'
import axios from "@/axios.js"
import { AgGridVue } from "ag-grid-vue"
import '@/assets/concept/scss/tabelaBasica.scss'
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import {Portuguese as PortugueseLocale} from 'flatpickr/dist/l10n/pt.js';
import { CONSTANTE_OPCAO_SIM_NAO } from "@/constantesComboBox.js";

const URL_SERVLET = "/rest/VeiculoHistorico/Listar";
const NAME_ROUTE_EDIT = "cadastro-ocorrencia-veiculo-editar";

export default {
  components: {
    AgGridVue,
    flatPickr
  },
  data() {
    return {
      errorsValidacao: [],
      filtro : {nomeCliente:  null, placaVeiculo: null, nomeUsuario: null, dataHoraOcorrenciaInicio: null, dataHoraOcorrenciaFim: null, dataHoraTratativaInicio: null, dataHoraTratativaFim: null},
      configuracaoGrid: { 
          gridApi: null, gridOptions: {}, 
          defaultColDef: { sortable: true, resizable: true, suppressMenu: true  },
          columnDefs: [                    
            { headerName: 'Cliente', field: 'cliente.nome', width: 130 },
            { headerName: 'Veiculo', field: 'veiculo.placa', width: 80,
              valueGetter: function(params) {
                return params.data.veiculo.placa + " - " + params.data.veiculo.modelo;
              }
            },
            { headerName: 'Cadastro', field: 'dataHoraCadastroFormatada', width: 80 },            
            { headerName: 'Data Contato', field: 'dataHoraContatoComClienteFormatada', width: 80 },
            { headerName: 'Usuário', field: 'usuario.nome', width: 80 },
            { headerName: 'Tratativa', field: 'tratativa' },
            { headerName: 'Desconsiderar', field: 'desconsiderarAteFormatada' },
          ]
      },
      dadosDaGrid: [],
      totalRegistros: 0,
      configDatePicker: {          
            altInput: true, 
            altFormat: "d-m-Y",
            dateFormat: "U", 
            altInputClass: "vs-inputx vs-input--input normal hasValue text-center ml-2 mr-2",
            locale: PortugueseLocale
      },     
      configDateYmdPicker: {
        altInput: true,
        altFormat: "d-m-Y",
        dateFormat: "Y-m-d",
        altInputClass: "vs-inputx vs-input--input normal hasValue text-center",
        locale: PortugueseLocale,
      },
      configDateTimePicker: {
        altInput: true,
        altFormat: "d-m-Y H:i",
        dateFormat: "Y-m-d H:i:S",
        altInputClass: "vs-inputx vs-input--input normal hasValue text-center",
        locale: PortugueseLocale,
        enableTime: true,
        minuteIncrement: 1,
      },
      popUpOcorrencia: {
        exibir: false,
        dataHoraContatoComCliente: null,
        nomeContato: null,
        telefoneContato: null,
        emManutencao: null,
        descricao: null,
        tratativa: null,
        respostaCliente: null,
        desconsiderarAte: null,
        tipoSelecaoLinha: null
      },
      qtdRegistrosSelecionados: 0
    }
  },  
  created() {    
    this.listaOpcoesSIM_NAO = CONSTANTE_OPCAO_SIM_NAO;
  },
  beforeMount(){
    this.tipoSelecaoLinha = 'multiple';
  },
  methods: {
    onGridReady(params) {
      this.configuracaoGrid.gridApi = params.api;
      this.configuracaoGrid.gridColumnApi = params.columnApi;
      this.configuracaoGrid.gridApi.sizeColumnsToFit();      
      this.fetchLista(this.filtro);      
    },
    onSelectionChanged() {
      var selectedRows = this.configuracaoGrid.gridApi.getSelectedRows();
      this.qtdRegistrosSelecionados = selectedRows.length;      
    },
    exibirPopUpRegistroOcorrencia(){
      var selectedRows = this.configuracaoGrid.gridApi.getSelectedRows();
      this.popUpOcorrencia.exibir = true;      
      let dataFromTS = new Date();
      let dataCorrente = dataFromTS.getUTCFullYear() + "-" + generico.zeroPad((dataFromTS.getUTCMonth() + 1),2)  + "-" +  generico.zeroPad(dataFromTS.getUTCDate(),2)
      + " " +  generico.zeroPad(dataFromTS.getHours(),2) + ":" +  generico.zeroPad(dataFromTS.getMinutes(),2) + ":" + generico.zeroPad(dataFromTS.getSeconds(),2);
      
      this.popUpOcorrencia.dataHoraContatoComCliente = dataCorrente;
      this.popUpOcorrencia.descricao = selectedRows[0].descricao;       
      this.popUpOcorrencia.nomeContato = null;
      this.popUpOcorrencia.telefoneContato = null;
      this.popUpOcorrencia.emManutencao = null;
      this.popUpOcorrencia.tratativa = null;
      this.popUpOcorrencia.respostaCliente = null;
      this.popUpOcorrencia.desconsiderarAte = null;        
    },
    limparFiltros() {
      this.filtro = {nomeCliente:  null, placaVeiculo: null, nomeUsuario: null, dataHoraOcorrenciaInicio: null, dataHoraOcorrenciaFim: null, dataHoraTratativaInicio: null, dataHoraTratativaFim: null};      
    },
    fetchLista(pFiltro) {     
      this.$vs.loading() 
      const params = new URLSearchParams();
      params.append('pagina', -1);
      
      if (pFiltro.nomeCliente) {
        params.append('cliente.nome', pFiltro.nomeCliente);
      }

      if (pFiltro.placaVeiculo) {
        params.append('veiculo.placa', pFiltro.placaVeiculo);
      }

      if (pFiltro.nomeUsuario) {
        params.append('usuario.nome', pFiltro.nomeUsuario);
      }

      if (pFiltro.dataHoraOcorrenciaInicio) {
        params.append('dataHoraOcorrenciaInicio', pFiltro.dataHoraOcorrenciaInicio*1000);
        params.append('dataHoraOcorrenciaFim', pFiltro.dataHoraOcorrenciaFim*1000);
      }

      if (pFiltro.dataHoraTratativaInicio) {
        params.append('dataHoraTratativaInicio', pFiltro.dataHoraTratativaInicio*1000);
        params.append('dataHoraTratativaFim', pFiltro.dataHoraTratativaFim*1000);
      }

      axios.post(URL_SERVLET, params, {              
              credentials: 'include',
              responseEncoding: 'iso88591',
              withCredentials: true } )
      .then((response) => {
          this.$vs.loading.close();
          if (response.data.hasOwnProperty("codigo") && response.data.codigo === "99" ) {
              this.$router.push({ path: '/' });
          } else {
              this.totalRegistros = response.data.Resultado.quantidade;
              if (response.data.Resultado.quantidade > 1) {
                  this.dadosDaGrid = response.data.Resultado.lista.item;
              } else if (response.data.Resultado.quantidade === 1) {
                  this.dadosDaGrid = [];
                  this.dadosDaGrid.push(response.data.Resultado.lista.item);
              } else {
                  this.dadosDaGrid = [];
              }                   
          }
      })
      .catch((error) => { 
          this.$vs.loading.close();
          /* eslint-disable no-console */ console.log("EFETUAR LOGOFF! - fetchLista - " + error); 
      })    
                        
    },
    routeNovoRegistro() {
      let self_ = this;

      self_.$router.push({
            name: NAME_ROUTE_EDIT                     
      })
    },
    onRowSelectedRegistro(evento) {
      let self_ = this;
      let registro = evento.node.data;      
      registro.dataHoraContatoComCliente  = String(registro.dataHoraContatoComClienteFormatadaYmdHiS);
            
      self_.$router.push({
            name: NAME_ROUTE_EDIT,
            params: {
              registroOriginal: registro
            }
      })
    },
    alterarEmLote() {
      if (this.formularioValido()) {
        this.$vs.loading();

        const params = new URLSearchParams();
        var ids = null;
        var idsVeiculos = null;

        var listaSelecionados = this.configuracaoGrid.gridApi.getSelectedRows();

        for (var i= 0; i < listaSelecionados.length; i++){
            if (ids === null) {
                ids = new String();     
                idsVeiculos = new String();           
                ids = listaSelecionados[i].id;                
                idsVeiculos = listaSelecionados[i].veiculo.id;
            } else {                
                ids = ids + "," + listaSelecionados[i].id;           
                idsVeiculos = idsVeiculos + "," + listaSelecionados[i].veiculo.id;     
            }
        }

        params.append("idsOcorrencias", ids);        
        params.append("idsVeiculos", idsVeiculos);        
        params.append("dataHoraContatoComCliente", this.popUpOcorrencia.dataHoraContatoComCliente);
        params.append("nomeContato", this.popUpOcorrencia.nomeContato);
        params.append("telefoneContato", this.popUpOcorrencia.telefoneContato);
        if (this.popUpOcorrencia.emManutencao) {
          params.append("emManutencao", this.popUpOcorrencia.emManutencao);
        }
        params.append("descricao", this.popUpOcorrencia.descricao);
        params.append("tratativa", this.popUpOcorrencia.tratativa);
        params.append("respostaCliente", this.popUpOcorrencia.respostaCliente);
        if (this.popUpOcorrencia.desconsiderarAte) {
          params.append("desconsiderarAte", this.popUpOcorrencia.desconsiderarAte);
        }

        axios.post("/rest/VeiculoHistorico/Gravar", params, {
            credentials: "include",
            responseEncoding: "iso88591",
            withCredentials: true,
          })
          .then((response) => {
            this.$vs.loading.close();
            if (response.data.hasOwnProperty("codigo") && response.data.codigo === "99") {
              this.$router.push({ path: "/login" });
            } else {
              if (response.data.Resultado.hasOwnProperty("error")) {
                this.$vs.dialog({  color: "danger", title: "Erro", text: response.data.Resultado.error, acceptText: "OK"});
              } else {
                this.popUpOcorrencia.exibir = false;                
                this.fetchLista(this.filtro);
              }
            }
          })
          .catch((error) => {
            this.$vs.loading.close();
            /* eslint-disable no-console */ console.log("EFETUAR LOGOFF! - gravar - " + error);
          });
      }
    },
    formularioValido() {
            this.$refs.errosComponent.iniciar();
            this.errorsValidacao = [];

            if (!this.popUpOcorrencia.dataHoraContatoComCliente) { this.errorsValidacao.push("Data e Hora de Contato com o cliente obrigatório."); }
            if (!this.popUpOcorrencia.nomeContato) { this.errorsValidacao.push("Nome do Contato obrigatório"); }
            if (!this.popUpOcorrencia.telefoneContato) { this.errorsValidacao.push("Telefone do Contato obrigatório"); }
            if (!this.popUpOcorrencia.descricao) { this.errorsValidacao.push("Descrição obrigatória"); }
            if (!this.popUpOcorrencia.tratativa) { this.errorsValidacao.push("Tratativa obrigatória"); }
            if (!this.popUpOcorrencia.respostaCliente) { this.errorsValidacao.push("Resposta do cliente obrigatória"); }
            
            if (!this.errorsValidacao.length) {
                return true;
            }   
        
            return false;
    },
  },
  mounted() {
    if (this.$store.getters.filtroPesquisaOcorrencia) {
      this.filtro = JSON.parse(JSON.stringify(this.$store.getters.filtroPesquisaOcorrencia));        
    } else {
      let dataAtual = new Date();
      dataAtual.setHours(0,0,0,0);
      this.filtro.dataHoraOcorrenciaInicio = String(dataAtual.getTime() / 1000);
      this.filtro.dataHoraOcorrenciaFim = String(dataAtual.getTime() / 1000);
    }
  },
  beforeDestroy(){
    this.$store.dispatch('setDadosPesquisaOcorencias', this.filtro);
  }
}

</script>
<style lang="scss">
  .ag-grid-altura{
    height: calc(100vh - 250px);
  }

  .ag-grid-table-com-selecao {
      .ag-row-selected {
        background-color: #06f067 !important;
        color: rgb(0, 0, 0);        
      }

      .ag-ltr .ag-cell-focus {
          border: 1px solid #06f067 !important;
      }
  }

</style>
